




//$primary:   #3550c9;// #191919  ;
/*$custom-file-text: (
  en: "Browse",
  es: "Elegir",
  fr: "Choisir",
);*/
.bg-dark {background-color: rgb(72, 76, 85);}
@import"../../node_modules/bootstrap/scss/bootstrap"

